body {
    margin: 0;
    font-family: "Roboto", sans-serif;
    overflow-x: hidden;
}

.container {
    display: grid;
    grid-template-columns: 10vw 80vw 10vw;
    grid-template-rows: 50px 250px 700px auto auto auto 50px;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
    "header header header"
    "left title right"
    "left chart right"
    "left events right"
    "left prediction right"
    "left historical-container right"
    "left footer right";

    justify-items: center;
    padding-top: 50px;

}

.header {
    font-weight: bold;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    background-color: white;
    border-bottom: 1px solid black;
    padding-left: 30px;
    display: flex;
    align-items: center;
    z-index: 100;

    /* border: 1px orange solid; */
}

.goat-emoji {
    margin-right: 10px;
    font-size: 35px;
    position: absolute;
    right: 50px;
}

.title {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows:  auto auto auto;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
            "title-top"
    "title-main"
    "title-sub";
    grid-area: title;

    width: 80vw;
    max-width: 1120px;

    text-align: center;

    align-items: center;
    align-content: center;

    /*border: 1px red solid;*/
}

.title-top {
    grid-area: title-top;
    color: #afafaf;
    font-family: "Courier", monospace;
}

.title-main {
    grid-area: title-main;
    font-size: 40px;
    padding: 20px;
    font-weight: 600;
}

.title-sub {
    grid-area: title-sub;
    color: #6c6c6c;
    font-size: 18px;
     /*border: 1px #1ddc3e solid;*/

    line-height: 1.5;
    letter-spacing: 2px;
}

.party-box {
    padding: 8px;
    width: 35px;
    border: 1px solid;
    border-radius: 5px;
}

.party-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.party-name {
    margin-top: 5px;
}

.chart {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 60px 40px auto;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
    "chart-title"
    "headline"
    "chart-main";
    grid-area: chart;

    width: 80vw;

    max-width: 1120px;
    /*border: 1px red solid;*/

}

.chart-title {
    grid-area: chart-title;
    justify-content: center;
    align-items: center;
    text-align: center;
    /*border: 1px #ffa600 solid;*/
}

.headline {
    grid-area: headline;
    display: flex;
    padding-top: 10px;
    min-height: 30px;
    justify-content: space-evenly;
    align-items: center;
}
.chart-main {
    grid-area: chart-main;
    /* border: 1px #00ff48 solid; */
}

.events {
    grid-area: events;
    /* border: 1px #ffc400 solid; */
    width: 80vw;
    max-width: 1120px;
}


.prediction {
    grid-area: prediction;
    /*border: 1px red solid;*/
    width: 100%;
    max-width: 1200px;
    margin: 20px auto;
    text-align: center;
}

.prediction h3 {
    margin-top: 50px;
    color: #333;
    margin-bottom: 10px;
}

.prediction h2 {
    color: #666;
    margin-bottom: 20px;
    font-weight: normal;
}
.prediction table {
    width: 100%;
    border-collapse: collapse;
}

.prediction th, .prediction td {
    padding: 12px 0px;
    /*border: 1px solid #e0e0e0;*/
    text-align: left;
    font-size: 12px;
    border-bottom: 1px dashed #dcdcdc;
}

.prediction tr:last-child td {
    border-bottom: none;
}

.prediction td:not(:first-child) {
    text-align: right;
}

.prediction th:not(:first-child) {
    text-align: right;
}

.prediction th {
    /*background-color: #f5f5f5;*/
    font-weight: 600;
}

.historical-section {
    grid-area: historical-section;
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    padding: 20px;
}

.historical-title {
    margin-top: 100px;
    margin-bottom: 20px;
    width: 100%;
    text-align: center;
}


.historical-grid {
    display: grid;
    gap: 100px 50px;
    color: #333;
    align-items: stretch;
    grid-template-columns: repeat(3, 1fr);
}
.axis text {
    font-size: 20px;
}

.left {
    grid-area: left;
     /*border: 1px #7b00ff solid;*/
}

.right {
    grid-area: right;
    /* border: 1px #2fff00 solid; */
}

.footer {
    grid-area: footer;
    width: 100vw;
    background-color: #282c34;
    color: white;
    text-align: center;
    padding: 10px;
}

.footer-text {
    color: #c4c4c4;
}

.footer-name {
    color: #ff60f7;
    font-weight: bold;
}
.timeframe-controls {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-bottom: 20px;
}

.timeframe-controls label {
    cursor: pointer;
}

h3 {
    font-weight: normal;
}

/* history */
.year-button {
    padding: 10px 20px;
    border: none;
    background-color: #f2f2f2;
    transition: background-color 0.3s;
    margin-right: 10px;
    cursor: pointer;
    font-size: 20px;
    font-weight: 600;
}

.year-button:hover {
    background-color: #e0e0e0;
}

.year-button.active {
    background-color: #c0c0c0;
}

.historical-container {
    grid-area: historical-container;
    width: 100%;
    height: auto;
    padding-top: 150px;
    justify-content: center;
    align-items: center;
    /*border: 1px solid #ccc;*/
}

.year-buttons-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    width: 100%;
}

.election-intro {
    color: #414141;
    font-size: 20px;
    font-weight: 30;
    margin-bottom: 10px;
    letter-spacing: 0.5px; /* A bit of letter-spacing for aesthetics */
}

.election-year-heading {
    /*font-size: 28px;*/
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 60px;
}

.election-year-heading .conservatives,
.election-year-heading .labour {
    padding: 2px 5px;
    color: white;
    font-weight: 600;
}

.election-year-heading .conservatives {
    background-color: #0087DC;
}

.election-year-heading .labour {
    background-color: #E4003B;
}

.election-description {
    /*text-align: center;*/
    margin: 25px 0; /* Adding space above and below the text */
    font-size: 22px; /* Making the font a bit larger */
    line-height: 1.6; /* Increased line-height for better readability */
    letter-spacing: 0.5px; /* A bit of letter-spacing for aesthetics */
    max-width: 1200px; /* Limit the width for better readability */
    /*margin-left: auto; !* These two lines will center the text block if it's shorter than max-width *!*/
    /*margin-right: auto;*/
    min-height: 250px;
}

.chart-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto; /* These two lines will center the text block if it's shorter than max-width */
    margin-right: auto;
    height: 500px;
    max-width: 1000px; /* Limit the width for better readability */
    border: 1px solid #efefef;
}

.y-axis path,
.y-axis line {
}

.y-axis text {
    fill: #333; /* Color of the axis text */
    font-size: 16px; /* Font size of the axis text */
    font-family: 'Arial', sans-serif; /* Font family of the axis text */
}

.data-label {
    font-size: 16px;
    font-weight: bold;
}

.leader-change-label {
    font-size: 16px;
    font-weight: bold;
    z-index: 2;
}

.date-label {
    font-size: 16px;
    font-weight: bold;
    color: #333333;
}

/* Media Query for devices with max width of 900px */
@media (max-width: 1500px) {
    .historical-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

/* Media Query for devices with max width of 600px */
@media (max-width: 1000px) {
    .container {
        grid-template-columns: 100%;
        grid-template-rows: 300px auto auto auto auto auto 100px;
        /*grid-template-rows: 50px 300px auto auto 100px;*/
        grid-template-areas:
            "header"
            "title"
            "chart"
            "events"
            "prediction"
            "historical-container"
            "footer";
    }

    .header {
        height: 130px;
        font-size: 40px;
        padding-left: 50px;
    }

    .goat-emoji {
        margin-right: 30px;
        font-size: 60px;
        position: absolute;
        right: 80px;
    }

    .title {
        width: 80vw;
    }

    .title-main {
        font-size: 90px;
    }

    .chart {
        padding-top: 30px;
        width: 100vw;
        grid-template-columns: 1fr;
        grid-template-rows: auto auto auto;
        gap: 0px 0px;
        grid-auto-flow: row;
        grid-template-areas:
    "chart-title"
    "headline"
    "chart-main";
        grid-area: chart;
        align-items: center;
        justify-items: center;
        /*border: 1px red solid;*/

    }

    .chart-title {
        width: 100vw;
        font-size: 30px;
        grid-area: chart-title;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding-top: 100px;
        /*border: 1px #ffa600 solid;*/
    }

    .headline {
        width: 100vw;
        font-size: 35px;
        grid-area: headline;
        padding-top: 10px;
        min-height: 30px;
        justify-content: space-evenly;
        align-items: center;
    }

    .party-box {
        padding: 10px;
        width: auto;
    }

    .chart-main {
        grid-area: chart-main;
        /* border: 1px #00ff48 solid; */

    }
    .prediction {
        width: 98%;
    }


    .prediction h3 {
        margin-top: 40px;
        margin-bottom: 10px;
    }

    .prediction h2 {
        color: #ed36fc;
        font-weight: bold;
        padding-bottom: 50px;
    }

    .prediction th {
        padding: 12px 5px;
        font-size: 25px;
    }

    .prediction td {
        padding: 50px 5px;
        font-size: 30px;
    }

    .prediction th {
        /*background-color: #f5f5f5;*/
        font-weight: 600;
    }

    .historical-container {
        width: 95%;
    }

    .chart-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 95%;
        width: 95%;
    }

    .year-buttons-container {
        flex-wrap: wrap;
    }

    .year-button {
        margin: 5px;
    }

    .left, .right {
        display: none; /* This hides the left and right areas */
    }
}